'use client';
import React, { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@nextui-org/react';
import CalendarIcon from '@/assets/svgs/calendaricon.png';
import FeesIcon from '@/assets/svgs/fees-icon.png';
import locationIcon from '@/assets/svgs/locationicon.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import '@/app/globals.css';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaIndianRupeeSign } from 'react-icons/fa6';

const LandingEventsCards = ({ opportunities, adminMode = false }) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isresponsive, setIsResponsive] = useState('vertical');
  const swiperRef = useRef(null);

  useEffect(() => {
    if (session && status === 'authenticated') {
      setLoggedIn(true);
    }
  }, [session, status]);

  useEffect(() => {
    const handleResize = () => {
      const newOrientation =
        window.innerWidth >= 768 ? 'horizontal' : 'vertical';
      setIsResponsive(newOrientation);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getEventStatus = (startDate, endDate) => {
    const currentDate = new Date().getTime();
    startDate = new Date(startDate).setHours(0, 0, 0, 0);
    endDate = new Date(endDate).setHours(23, 59, 59, 999);

    if (currentDate >= startDate && currentDate <= endDate) {
      return 'ongoing';
    } else if (currentDate >= endDate) {
      return 'ended';
    } else {
      return 'upcoming';
    }
  };

  if (!opportunities || opportunities.length === 0) {
    return (
      <div className="flex justify-center items-center py-10">
        <p className="text-gray-500 text-lg">
          No events available at the moment.
        </p>
      </div>
    );
  }

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const getRegistrationStatus = opportunity => {
    if (!opportunity?.registration_deadline) return false;

    const deadline = new Date(opportunity.registration_deadline);
    deadline.setHours(23, 59, 59, 999);

    return deadline < currentDate;
  };

  const getEventLinks = opportunity => {
    const opportunityRef = opportunity?.alias || opportunity?.id;
    const eventLink = opportunity?.is_external_event
      ? opportunity?.external_link
      : `/events/${opportunityRef}`;
    const dashboardLink = `/events/${opportunityRef}/dashboard`;

    return { eventLink, dashboardLink };
  };

  const handleCardClick = opportunity => {
    const { eventLink } = getEventLinks(opportunity);
    router.push(eventLink);
  };

  return (
    <>
      {isresponsive === 'horizontal' ? (
        <div>
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={4}
            navigation={true}
            pagination={{
              clickable: true,
              bulletActiveClass: 'swiper-pagination-bullet-active',
              bulletClass: 'swiper-pagination-bullet',
              renderBullet: function (index, className) {
                return `<span class="${className}"></span>`;
              },
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 3,
              },
              620: {
                slidesPerView: 2,
                spaceBetween: 3,
              },
              1000: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1410: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1500: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {opportunities.map(opportunity => {
              const eventStatus = getEventStatus(
                opportunity.start_date,
                opportunity.end_date
              );

              return (
                <SwiperSlide
                  onClick={() => handleCardClick(opportunity)}
                  key={opportunity.id}
                  className="py-[60px]"
                >
                  <Card className="mx-10 border-cardBorder border-1 rounded-2xl bg-white">
                    <CardHeader className="p-0">
                      <div className="relative w-full h-full">
                        <Image
                          src={
                            opportunity.opportunity_main_picture
                              ? opportunity?.opportunity_main_picture
                              : 'https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
                          }
                          alt="Home"
                          width={100}
                          height={100}
                          objectFit="cover"
                          className="rounded-t-2xl w-full"
                        />
                        {eventStatus === 'ongoing' && (
                          <div className="absolute top-2 left-1/2 -translate-x-1/2 bg-green-600 text-white px-2 py-1 rounded-md text-xs font-semibold z-50">
                            LIVE
                          </div>
                        )}
                        {eventStatus === 'ended' && (
                          <div className="absolute top-2 left-1/2 -translate-x-1/2 bg-red-600 text-white px-2 py-1 rounded-md text-xs font-semibold">
                            ENDED
                          </div>
                        )}
                        {eventStatus === 'upcoming' && (
                          <div className="absolute top-2 left-1/2 -translate-x-1/2 bg-orange-400 text-white px-2 py-1 rounded-md text-xs font-semibold">
                            ONGOING
                          </div>
                        )}
                      </div>
                    </CardHeader>

                    <CardBody className="py-2 gap-2">
                      <h2 className="text-purple-700 font-sans font-bold text-lg">
                        {opportunity.is_paid
                          ? 'PAID REGISTRATION'
                          : 'FREE REGISTRATION'}
                      </h2>
                      <div className="flex items-center justify-between h-10">
                        <p className="text-[#000000] text-md font-sans font-medium line-clamp-2">
                          {opportunity?.name}
                        </p>
                      </div>

                      <div className="flex flex-col gap-2">
                        {/* Location and potentially date */}
                        <div className="flex flex-wrap w-full justify-between">
                          <div className="flex items-center gap-2 min-w-0">
                            <Image
                              src={locationIcon}
                              width={24}
                              height={24}
                              alt="Location Icon"
                            />
                            <div className="text-[#4F4F4F] font-sans text-md truncate">
                              {opportunity?.mode === 'In Person'
                                ? opportunity?.location
                                : 'Online'}
                            </div>
                          </div>

                          {/* Show date on same line only if location is 'Online' or location text is short */}
                          {(opportunity?.mode !== 'In Person' ||
                            opportunity?.location?.length < 30) && (
                            <div className="flex items-center gap-2">
                              <Image
                                src={CalendarIcon}
                                width={18}
                                height={18}
                                alt="Calendar Icon"
                              />
                              <span className="text-gray-600 font-sans text-md">
                                {new Date(
                                  opportunity?.start_date
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Show date with fees if location is in-person or location text is long */}
                        {opportunity?.mode === 'In Person' &&
                          opportunity?.location?.length >= 30 && (
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center gap-2">
                                <Image
                                  src={CalendarIcon}
                                  width={18}
                                  height={18}
                                  alt="Calendar Icon"
                                />
                                <span className="text-gray-600 font-sans text-md">
                                  {new Date(
                                    opportunity?.start_date
                                  ).toLocaleDateString()}
                                </span>
                              </div>

                              <div className="flex items-center gap-2">
                                <Image
                                  src={FeesIcon}
                                  width={18}
                                  height={18}
                                  alt="Fees Icon"
                                />
                                <span className="flex items-center text-red-600 font-sans font-semibold">
                                  {opportunity?.is_paid ? (
                                    <>
                                      <FaIndianRupeeSign className="gap-0 h-3 w-3 text-slate-950" />
                                      <FaIndianRupeeSign className="gap-0 h-3 w-3 text-slate-950" />
                                      <p className="text-sm">
                                        {' '}
                                        {opportunity?.fees}
                                      </p>
                                    </>
                                  ) : (
                                    <p className="text-sm">Free</p>
                                  )}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* Show fees alone if date is shown with location */}
                        {(opportunity?.mode !== 'In Person' ||
                          opportunity?.location?.length < 30) && (
                          <div className="flex items-center gap-2">
                            <Image
                              src={FeesIcon}
                              width={18}
                              height={18}
                              alt="Fees Icon"
                            />
                            <span className="text-red-600 font-sans font-semibold">
                              {opportunity?.is_paid ? (
                                <div className="flex items-center ">
                                  <FaIndianRupeeSign className="h-3 w-3 text-slate-950" />
                                  <p className="text-sm">
                                    {opportunity?.fees} /-Only
                                  </p>
                                </div>
                              ) : (
                                <p className="text-sm">Free</p>
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </CardBody>

                    <CardFooter className="w-full py-2">
                      {adminMode ? (
                        <Link
                          href={getEventLinks(opportunity).dashboardLink}
                          className="w-full"
                        >
                          <Button className="w-full text-xl bg-[#6956e3] text-white">
                            Go to dashboard
                          </Button>
                        </Link>
                      ) : opportunity?.is_verified ? (
                        <Link
                          href={getEventLinks(opportunity).eventLink}
                          className="w-full"
                        >
                          <Button
                            variant="ghost"
                            color="none"
                            style={{
                              backgroundColor: getRegistrationStatus(
                                opportunity
                              )
                                ? '#404040'
                                : opportunity?.is_registered
                                  ? 'rgba(9, 121, 105, 0.1)' // #097969 with 10% opacity
                                  : '#097969',
                              color: getRegistrationStatus(opportunity)
                                ? '#ffffff'
                                : opportunity?.is_registered
                                  ? '#000000'
                                  : '#ffffff',
                              cursor: getRegistrationStatus(opportunity)
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            className="w-full text-xl shadow-inner-glow hover:shadow-inner-glow-hover hover-bg-[#005D44] border-1 border-[#097969] text-lg font-sans py-4 text-white rounded-xl"
                            disabled={getRegistrationStatus(opportunity)}
                          >
                            {getRegistrationStatus(opportunity)
                              ? 'Registration Closed'
                              : opportunity?.is_registered
                                ? 'View Event Recap'
                                : 'Register Now'}
                          </Button>
                        </Link>
                      ) : null}
                    </CardFooter>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
        >
          {opportunities.map(opportunity => {
            const eventStatus = getEventStatus(
              opportunity.start_date,
              opportunity.end_date
            );

            return (
              <SwiperSlide
                key={opportunity.id}
                onClick={() => handleCardClick(opportunity)}
              >
                <div className="flex justify-center px-1 pb-10">
                  <Card className="flex flex-row space-y-2 bg-white shadow-lg w-full">
                    <Image
                      src={
                        opportunity.opportunity_main_picture
                          ? opportunity?.opportunity_main_picture
                          : 'https://images.unsplash.com/photo-1613545325278-f24b0cae1224?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
                      }
                      width={120}
                      height={120}
                      objectFit="cover"
                      alt="Home"
                      className="object-cover rounded-lg "
                    />

                    <div className="w-full">
                      <CardBody className="py-0">
                        <div className="space-y-1">
                          <h2 className="text-purple-700 font-sans font-bold text-sm md:text-base">
                            {opportunity?.is_paid
                              ? 'PAID REGISTRATION'
                              : 'FREE REGISTRATION'}
                          </h2>
                          <p className=" items-center text-[#000000] text-xs md:text-sm font-sans font-medium line-clamp-1 h-8">
                            {opportunity?.name}
                          </p>

                          <div className="flex items-center gap-1">
                            <Image
                              src={locationIcon}
                              width={18}
                              height={18}
                              alt="Location Icon"
                            />
                            <div className="text-[#4F4F4F] font-sans text-xs md:text-sm truncate line-clamp-1">
                              {opportunity?.mode === 'In Person'
                                ? opportunity?.location
                                : 'Online'}
                            </div>
                          </div>

                          <div className="flex items-center whitespace-nowrap mt-2 gap-3">
                            <div className="flex items-center gap-1">
                              <Image
                                src={CalendarIcon}
                                width={14}
                                height={14}
                                alt="Calendar Icon"
                              />
                              <span className="text-gray-600 text-xs md:text-sm">
                                {new Date(
                                  opportunity?.start_date
                                ).toLocaleDateString()}
                              </span>
                            </div>

                            <div className="flex items-center gap-1">
                              <Image
                                src={FeesIcon}
                                width={14}
                                height={14}
                                alt="Fees Icon"
                              />
                              <span className="text-red-600 font-medium text-xs md:text-sm">
                                {opportunity?.is_paid
                                  ? opportunity?.fees
                                  : 'Free'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                      <CardFooter className="p-1 text-sm md:text-base flex justify-end ">
                        {opportunity?.is_verified ? (
                          <Link
                            href={getEventLinks(opportunity).eventLink}
                            className="flex items-center gap-2"
                          >
                            <span>Read More</span>
                            <FaArrowCircleRight className="text-2xl text-green-900" />
                          </Link>
                        ) : null}
                      </CardFooter>
                    </div>
                  </Card>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};

export default LandingEventsCards;
