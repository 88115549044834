'use client';
import { useState, useEffect } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Input,
} from '@nextui-org/react';
import Image from 'next/image';
import filter from '@/assets/svgs/filter.svg';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import axios from 'axios';

const FilterPopover = ({ opportunities, onApplyFilters, search, onSearchChange, onClearFilters }) => {
  // const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({
    opportunity_type: [],
    mode: [],
    fees: [],
  });
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    handleApplyFilters();
  }, [search]);

  const handleSearchChange = e => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (category, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter(item => item !== value)
        : [...prev[category], value],
    }));
  };

  // const handleApplyFilters = () => {
  //   onApplyFilters(selectedFilters);
  // };

  const handleApplyFilters = () => {
    onApplyFilters({ filters: selectedFilters, searchTerm: search });
  };

  // const handleClearFilters = (category, value) => {
  //   setSelectedFilters(prev => ({
  //     ...prev,
  //     [category]: [],
  //     [value]: [],
  //   }));
  // };

  const handleClearFilters = category => {
    if (category === 'search') {
      onSearchChange(''); // Clear search term
    } else {
      setSelectedFilters(prev => ({
        ...prev,
        [category]: [],
      }));
    }
    onApplyFilters({ filters: selectedFilters, searchTerm: search });
  };

  const filters = [
    {
      id: 'opportunity_type',
      name: 'Event Type',
      options: [
        { value: 'Hackathon', label: 'Hackathon' },
        { value: 'Bootcamp', label: 'Bootcamp' },
        { value: 'Workshop', label: 'Workshop' },
      ],
    },
    {
      id: 'mode',
      name: 'Mode',
      options: [
        { value: 'Online', label: 'Online' },
        { value: 'In Person', label: 'Offline' },
      ],
    },
    {
      id: 'fees',
      name: 'Registration Fee',
      options: [
        { value: 'false', label: 'Free' },
        { value: 'true', label: 'Paid' },
      ],
    },
  ];

  return (
    <div
      className={`lg:relative fixed lg:top-0 bottom-0 left-0 right-0 z-50 lg:z-auto ${isScrolled ? 'block' : 'lg:block hidden'}`}
    >
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Button
            className="md:px-8 w-full md:w-auto bg-white md:bg-white text-black font-medium text-base lg:text-lg py-4"
            variant="bordered"
            size="lg"
            startContent={
              <Image src={filter} alt="filter" width={20} height={20} />
            }
          >
            FILTER
          </Button>
        </PopoverTrigger>

        <PopoverContent
          variant="bordered"
          className="p-6 space-y-4 lg:w-[450px]"
        >
          <div className="flex justify-between font-medium w-full text-lg ">
            <div className="text-lg">Search by Keywords</div>
            <button className="text-green-900" onClick={() => handleClearFilters('search')}>
              Clear
            </button>
          </div>
          <Input
            placeholder="Search.."
            size="lg"
            className="w-full text-lg "
            startContent={
              <FaMagnifyingGlass className="text-[#757575]" size={24} />
            }
            value={search}
            onChange={handleSearchChange}
          />

          {filters.map(filter => (
            <div key={filter.id} className="space-y-3 w-full">
              <div className="flex justify-between w-full text-lg">
                <div>{filter.name}</div>
                <button
                  className="text-green-900"
                  onClick={() => handleClearFilters(filter.id)}
                >
                  Clear
                </button>
              </div>
              <div className="grid grid-cols-3 gap-3 w-full">
                {filter.options.map(option => (
                  <Button
                    key={option.value}
                    variant="bordered"
                    radius="full"
                    size="lg"
                    onClick={() => handleFilterChange(filter.id, option.value)}
                    className={`text-sm w-full ${
                      selectedFilters[filter.id].includes(option.value)
                        ? 'bg-green-600 text-white'
                        : 'text-gray-500'
                    }`}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            </div>
          ))}

          <div className="flex justify-between w-full gap-4 py-4 mt-4">
            <Button
              variant="bordered"
              radius="3xl"
              size="lg"
              className="text-sm border-green-900 text-black text-lg flex-1"
              // onClick={() =>
              //   setSelectedFilters({
              //     opportunity_type: [],
              //     mode: [],
              //     fees: [],
              //   })
              // }
              onClick={onClearFilters}
              
            >
              Cancel
            </Button>

            <Button
              variant="ghost"
              radius="3xl"
              size="lg"
              className="text-sm border-green-900 text-black bg-[#097969] text-lg flex-1 text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow"
              onClick={handleApplyFilters}
            >
              Apply Now
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default FilterPopover;
