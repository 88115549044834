'use client';
import { useState } from 'react';
import LandingEventsCards from './LandingEventsCards';
import FilterPopover from './FilterPopover';
import { BackgroundMask } from '../commons/BackgroundMask';
import { usePathname } from 'next/navigation';

const ExclusiveEventsCards = ({ opportunities }) => {
  const pathname = usePathname();
  const [filteredOpportunities, setFilteredOpportunities] =
    useState(opportunities);
  const [search, setSearch] = useState('');

  const applyFilters = ({ filters, searchTerm }) => {
    const { opportunity_type, mode, fees } = filters;

    const filtered = opportunities.filter(event => {
      const matchesType = !opportunity_type.length || opportunity_type.includes(event.opportunity_type);
      const matchesMode = !mode.length || mode.includes(event.mode);
      const matchesFee = !fees.length || fees.includes(event.fee === 0 ? 'Free' : 'Paid');
      const matchesSearch = !searchTerm || event.name.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesType && matchesMode && matchesFee && matchesSearch;
    });

    setFilteredOpportunities(filtered);
  };

  const handleClearFilters = () => {
    setSearch(''); 
    applyFilters({
      filters: { opportunity_type: [], mode: [], fees: [] },
      searchTerm: '',
    }); 
  };

  return (
    <BackgroundMask>
      <div className="pt-[60px] mb-4 md:my-0">
        <div className="layoutBox space-y-6 md:space-y-0">
          <div className="flex flex-col space-y-1 font-sans">
            <div className="relative flex justify-between items-center">
              <h1 className="md:text-[40px] text-lg font-medium flex-grow text-center">
                Hackingly Exclusive
              </h1>
              {pathname === '/event' && (
                <div className="absolute right-0">
                  <FilterPopover
                    onApplyFilters={applyFilters}
                    search={search}
                    onSearchChange={setSearch}
                    onClearFilters={handleClearFilters}
                  />
                </div>
              )}
            </div>
            <div className="md:pt-4 md:tracking-wide tracking-wide md:text-xl text-sm text-[#4F4F4F] text-center">
              Explore The Diverse Range Of Exclusive Events
            </div>
          </div>
          <LandingEventsCards opportunities={filteredOpportunities} />
        </div>
      </div>
    </BackgroundMask>
  );
};

export default ExclusiveEventsCards;
